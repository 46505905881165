import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as BusinessCrossroad } from 'assets/streamline-light/business-products/business-metaphors/business-crossroad.svg';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as HouseChimney1 } from 'assets/streamline-light/interface-essential/home/house-chimney-1.svg';
import { ReactComponent as TripDistance } from 'assets/streamline-light/maps-navigation/trip/trip-distance.svg';
import { ReactComponent as MultipleMan } from 'assets/streamline-light/users/geomertic-close-up-multiple-users/multiple-man.svg';
import { ReactComponent as BuyerFinderIcon } from 'assets/streamline-light/users/geometric-full-body-single-user-neutral/single-neutral-find.svg';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { SecondaryButton } from 'modules/common/components/ui/buttons';
import { siteMap } from 'routes/site-map';
import { ThemeContext } from 'styled-components';
import {
  energyLabelOptions,
  energyLabelOptionsSwitzerland,
} from 'modules/forms/form-property-valuation-wizard-v2/constants';
import { BuyerFinderPage } from 'modules/property/components/tabs/buyer-finder/buyer-finder-page';

import {
  BlockType,
  CmsBlock,
  Coordinates,
  Transport,
  useCmsPageQuery,
  useCmsPagesQuery,
  usePropertyQuery,
  ValuationType,
} from '../../../../generated';
import { PropertiesTab } from '../../../common/components/layout/header/properties-tab';
import { ScrollDetector } from '../../../common/components/scroll-detector';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { AddressBar } from '../../../common/components/ui/hero-slider/address-bar';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { IframeMap } from '../../../map/components/iframe-map';
import { Mapbox } from '../../../map/components/mapbox';
import { Europe } from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  restoreCheckpoints,
} from '../../../map/redux/mapSlice';
import { OnlineValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/online-valuation/online-valuation-container';
import { PersonalValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/personal-valuation/personal-valuation-container';
import { EditValuationModal } from '../../../forms/form-property-valuation-wizard/components/modals/edit-valuation-modal';
import {
  closeValuationWizardAction,
  resetValuationWizard,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { PropertyValuationOverviewCard } from '../../components/card/property-valuation-overview-card';
import { Drawer } from '../../components/drawer/drawer';
import { HeaderTab } from '../../components/header/valuation-header-tab';
import { VariationHeaderActions } from '../../components/header/variation-header-actions';
import { Overview } from '../../components/tabs/overview';
import { Reachability } from '../../components/tabs/reachability';
import { SimilarProperties } from '../../components/tabs/similar-properties';
import { setSelectedTab } from '../../redux/dynamicMapUtilsSlice';
import { implodeAddress } from '../../utils/address';
import { LngRedirect } from '../../../localization/lng-redirect';
import { SocialEconomicsPropertyWrapper } from '../../components/tabs/social-economics/social-economics-property-wrapper';
import { InfrastructurePropertyWrapper } from '../../components/tabs/infrastructure/infrastructure-property-wrapper';
import { PropertyNavigation } from './property-navigation/property-navigation';
import {
  ContentWithPadding,
  HeaderWrapper,
  IconWrapper,
  IFrameContainer,
  LeftSideMapboxContainer,
  MobileHeadContainer,
  MobileMapContainer,
  PanelContentRightWrapper,
  PanelContentWrapper,
  RowContainer,
} from './property-page-styles';
import { CTAButtons } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/cta-buttons/cta-buttons';
import { PropertyValuationWidgetContainer } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/property-valuation-widget-container';
import { useGetEvaluationWidgetVersion } from '../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { PageType } from '../../../forms/form-property-valuation-wizard/interface';
import { ValuationFirstStepScreen } from '../../../forms/form-property-valuation-wizard-v2/components/containers/property-valuation-containers/valuation-first-step-screen/valuation-first-step-screen';
import { setIsRegionalPage } from '../../../forms/form-property-valuation-wizard-v2/redux/valuationWizardV2Slice';
import { ValuationLoader } from '../../../forms/components/common/loader';
import { useOpenEvaluationWizardV2 } from '../../../forms/form-property-valuation-wizard-v2/hooks/callback-hooks/useOpenEvaluationWizardV2';
import { FullScreenMap } from '../../components/full-screen-map/full-screen-map';
import { toggleisEditProfileModalOpen } from '../../../auth/redux/authSlice';
import { formatPrice } from 'modules/seller/utils/formatPrice';

const PropertyPageBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const tabListRef = useRef<HTMLDivElement>(null);
  const isMobileSize = useIsMobileSize();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const openWidget = urlParams.get('openWidget') || '';
  const isOpenOnlineModal = urlParams.get('online-modal') === 'true';
  const propertyNotification = urlParams.get('property-notification') || '';
  const themeContext = useContext(ThemeContext);
  const [isPropertyLoaded, setIsPropertyLoaded] = useState(false);
  const locationReactDom = useLocation();
  const history = useHistory();

  const { selectedTab, isTabListNearTop, isReachabilityTab } = useAppSelector(
    (state) => state.dynamicMapUtils
  );
  const {
    activeStep,
    valuationType,
    floatingBar,
    isValuationWizardOpen,
    selectedAddress,
  } = useAppSelector((state) => state.valuationWizard);
  const placeName = selectedAddress?.place_name;
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();
  const { push } = useHistory();

  const [hasMultipleProperties, setHasMultipleProperties] =
    useState<boolean>(false);

  // get toggle option for property tab (IMMOBILIEN)
  const { propertyPage } = useCmsPagesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      propertyPage: data?.cmsPages?.find(
        (page) => page.name === 'Property Page'
      ),
    }),
  });

  const { isActive: isPropertyTabActive } = useCmsPageQuery(
    {
      id: propertyPage?._id ?? '',
    },
    {
      selectFromResult: ({ data }) =>
        data?.cmsPage?.blocks.find(
          (block) => block.type === BlockType.PropertyTab
        ) as CmsBlock,
    }
  );
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({
    isLandingPage: false,
  });

  const {
    overviewProps,
    address: propertyAddress,
    isLoading: isPropertyLoading,
    isSuccess: isFetchingDone,
    isFetching: isPropertyFetching,
    error: propertyErrors,
    dossierUrl: propertyDossierUrl,
    userStatus,
    poi: propertyPOI,
    countryCode: countryCodeBase,
    coordinates: propertyCoordinates,
    // status: propertyStatus,
    isContractSigned,
    isDigitalServiceActivated,
  } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, isFetching, error, isSuccess }) => {
        const {
          buildingYear,
          balconyArea,
          floorNumber,
          numberOfFloorsInBuilding,
          renovationYear,
          propertyType,
          livingArea,
          landArea,
          numberOfRooms,
          numberOfBathrooms,
          numberOfIndoorParkingSpaces,
          numberOfOutdoorParkingSpaces,
          numberOfUnits,
          annualRentIncome,
          location,
          energyLabel,
        } = data?.property?.propertyData || {};

        const {
          dossierUrl,
          userStatus: userStatusRaw,
          poi,
          status,
          isContractSigned: contractSigned,
          isDigitalServiceActivated: digitalServiceActivated,
          energyCertificate,
          countryCode,
          priceCurrency,
        } = data?.property || {};
        const { address, coordinates } = location || {};

        const isSwitzerland = countryCode === 'CH';
        const labelOfEnergyLabelField = isSwitzerland
          ? energyLabelOptionsSwitzerland.find(
              (item) => item.value === energyLabel
            )?.label
          : energyLabelOptions.find((item) => item.value === energyLabel)
              ?.label;

        const annualRentIncomeLabel = Number.isFinite(annualRentIncome)
          ? formatPrice(annualRentIncome!, priceCurrency || 'EUR')
          : '-';

        return {
          overviewProps: {
            buildingYear,
            code: propertyType?.code,
            livingArea,
            landArea,
            numberOfRooms,
            numberOfBathrooms,
            numberOfIndoorParkingSpaces,
            numberOfOutdoorParkingSpaces,
            balconyArea,
            floorNumber,
            numberOfFloorsInBuilding,
            renovationYear,
            annualRentIncome,
            annualRentIncomeLabel,
            numberOfUnits,
            energyLabel: energyLabel
              ? labelOfEnergyLabelField
              : energyCertificate?.energyEfficiencyClass || null,
          },
          error,
          isLoading,
          isSuccess,
          isFetching,
          dossierUrl,
          address: implodeAddress(address),
          coordinates,
          userStatus: userStatusRaw ?? null,
          poi,
          countryCode,
          status,
          isContractSigned: Boolean(contractSigned),
          isDigitalServiceActivated: Boolean(digitalServiceActivated),
        };
      },
    }
  );

  const closeEvaluationWizard = () => {
    // Heat map
    if (selectedTab === 0) {
      dispatch(
        changeViewport({
          ...Europe,
          transitionDuration: 1000,
        })
      );
      setTimeout(() => {
        batch(() => {
          dispatch(closeValuationWizardAction());
          dispatch(resetValuationWizard());
        });
      }, 500);
    } else {
      batch(() => {
        dispatch(closeValuationWizardAction());
        dispatch(resetValuationWizard());
        dispatch(restoreCheckpoints());
      });
    }
  };

  const onGoBack = useCallback(
    () => push(`${siteMap.PropertyPortfolioPage.path}`),
    [push]
  );

  // open widget from the landing page after login
  useEffect(() => {
    if (openWidget) {
      dispatch(setIsRegionalPage(true));
    }
    if (isOpenOnlineModal) {
      openEvaluationWizardV2(ValuationType.Online);
    }
    if (propertyNotification === '-1') {
      const queryParams = new URLSearchParams(locationReactDom.search);
      dispatch(toggleisEditProfileModalOpen(true));
      queryParams.delete('property-notification');
      history.replace({
        search: queryParams.toString(),
      });
    }
    return () => {
      if (openWidget) {
        dispatch(setIsRegionalPage(false));
      }
    };
  }, []);

  useEffect(() => {
    // Snapshot the first property loading, to avoid consecutive loading UI
    setIsPropertyLoaded(true);
  }, [isFetchingDone]);

  if (!isPropertyLoaded && (isPropertyLoading || isPropertyFetching)) {
    return <ValuationLoader maxWidth="500px" mb={0} />;
  }

  if (
    !isPropertyLoading &&
    (propertyErrors || (isFetchingDone && !propertyAddress))
  ) {
    return <LngRedirect to="/404" />;
  }

  return (
    <>
      {isIframe && (
        <IFrameContainer>
          {isRedesignVersion && openWidget ? (
            <ValuationFirstStepScreen />
          ) : (
            <PropertyValuationWidgetContainer />
          )}
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          {/* <PropertiesTab
            setHasMultipleProperties={setHasMultipleProperties}
            shouldShrink={isTabListNearTop}
          /> */}
          <PropertyNavigation />
          <>
            {!isMobileSize && (
              <Hero>
                <>
                  {propertyDossierUrl && (
                    <IframeMap
                      isVisible={[0, 5].includes(selectedTab)}
                      dossierUrl={propertyDossierUrl ?? ''}
                    />
                  )}
                </>

                <Mapbox
                  isVisible={
                    (isValuationWizardOpen || selectedTab > 0) &&
                    !(selectedTab === 2 && isTabListNearTop) &&
                    selectedTab !== 5
                  }
                />

                <HeroSlider
                  onGoBack={onGoBack}
                  showAddressBar={isValuationWizardOpen ? activeStep > 0 : true}
                  topOffset={-56}
                  address={isValuationWizardOpen ? placeName : propertyAddress}
                  showEditValuation
                  isPropertyMarketed={isDigitalServiceActivated}
                >
                  <PropertyValuationOverviewCard
                    header="property-valuation-overview-card.header"
                    title="property-valuation-overview-card.title"
                    content="property-valuation-overview-card.content"
                    {...overviewProps}
                  />
                  <Card
                    header={t('property-page.hero-slider.first-card.header')}
                    titleFirstLine={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.first-card.online.title.first-line'
                          )
                        : t(
                            'property-page.hero-slider.first-card.personal.title.first-line'
                          )
                    }
                    titleSecondLine={t(
                      'property-page.hero-slider.first-card.title.second-line'
                    )}
                    content={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.first-card.online.content'
                          )
                        : t(
                            'property-page.hero-slider.first-card.personal.content'
                          )
                    }
                    showAddressBar={
                      isValuationWizardOpen ? activeStep > 0 : true
                    }
                    cta={(c) => (
                      <IconWrapper onClick={closeEvaluationWizard}>
                        <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                        {c}
                      </IconWrapper>
                    )}
                  />
                  <Card
                    titleType="h2"
                    header={t('property-page.hero-slider.second-card.header')}
                    titleFirstLine={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.second-card.online.title.first-line'
                          )
                        : t(
                            'property-page.hero-slider.second-card.personal.title.first-line'
                          )
                    }
                    titleSecondLine={t(
                      'property-page.hero-slider.second-card.title.second-line'
                    )}
                    content={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.second-card.online.content'
                          )
                        : t(
                            'property-page.hero-slider.second-card.personal.content'
                          )
                    }
                    showAddressBar={
                      isValuationWizardOpen ? activeStep > 0 : true
                    }
                    cta={(c) => (
                      <IconWrapper onClick={closeEvaluationWizard}>
                        <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                        {c}
                      </IconWrapper>
                    )}
                  />
                </HeroSlider>
                <PropertyValuationWidgetContainer />
              </Hero>
            )}
          </>
          <>
            {isMobileSize && (
              <MobileHeadContainer>
                <PropertyValuationOverviewCard
                  header="property-valuation-overview-card.header"
                  title="property-valuation-overview-card.title"
                  content="property-valuation-overview-card.content"
                  {...overviewProps}
                />
                <RowContainer>
                  <SecondaryButton
                    label={t(
                      'property-valuation.address-bar.button.open-edit-modal'
                    )}
                    onClick={() => {
                      window.open(
                        `${
                          siteMap.SellingProcessPage.pathWithoutParams
                        }/${propertyId}?openTab=${
                          isDigitalServiceActivated ? '0' : '4'
                        }`,
                        '_blank'
                      );
                    }}
                    color={themeContext.blue}
                    borderColor={themeContext.blue}
                    style={{
                      width: '100%',
                    }}
                  />
                </RowContainer>

                <AddressBar
                  show={isValuationWizardOpen ? activeStep > 0 : true}
                  address={isValuationWizardOpen ? placeName : propertyAddress}
                  isShowFullMapButton={isReachabilityTab}
                  isPropertyPage
                />
                <FullScreenMap />
                <MobileMapContainer>
                  <>
                    {propertyDossierUrl && (
                      <IframeMap
                        isVisible={[0, 5].includes(selectedTab)}
                        dossierUrl={propertyDossierUrl ?? ''}
                      />
                    )}
                  </>
                  <>
                    <Mapbox
                      isVisible={
                        (isValuationWizardOpen || selectedTab > 0) &&
                        !(selectedTab === 2 && isTabListNearTop) &&
                        selectedTab !== 5
                      }
                    />
                  </>
                </MobileMapContainer>

                <Drawer
                  drawerTopContent={
                    <CTAButtons skipBorderRadius pageType={PageType.property} />
                  }
                  drawerBottomContent={
                    <VariationHeaderActions
                      userStatus={userStatus}
                      propertyId={propertyId}
                      isDeleteDisabled={isContractSigned}
                      isEditDisabled={isContractSigned}
                    />
                  }
                />

                {isValuationWizardOpen &&
                  valuationType === ValuationType.Online && (
                    <OnlineValuationContainer />
                  )}
                {isValuationWizardOpen &&
                  valuationType === ValuationType.Personal && (
                    <PersonalValuationContainer />
                  )}
              </MobileHeadContainer>
            )}
          </>
          <ContentWithPadding>
            <Tabs
              selectedIndex={selectedTab}
              onSelect={(index) => {
                dispatch(setSelectedTab(index));
              }}
            >
              <HeaderWrapper
                isTabListNearTop={isTabListNearTop}
                ref={tabListRef}
                floatingBar={floatingBar}
                hasMultipleProperties={hasMultipleProperties}
              >
                <TabList style={{ height: '100%', position: 'relative' }}>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('property-navigation.first-tab')}
                      icon={CommonFileText}
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('property-navigation.second-tab')}
                      icon={BusinessCrossroad}
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('property-navigation.third-tab')}
                      icon={HouseChimney1}
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('property-navigation.fourth-tab')}
                      icon={MultipleMan}
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('property-navigation.fifth-tab')}
                      icon={TripDistance}
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('buyer-finder.tab-label')}
                      icon={BuyerFinderIcon}
                    />
                  </Tab>
                </TabList>
                <>
                  {!isMobileSize && (
                    <VariationHeaderActions
                      userStatus={userStatus}
                      propertyId={propertyId}
                      isDeleteDisabled={isContractSigned}
                      isEditDisabled={isContractSigned}
                    />
                  )}
                </>
              </HeaderWrapper>
              <TabPanel>
                <Overview />
              </TabPanel>
              <TabPanel>
                <PanelContentWrapper isTabListNearTop={isTabListNearTop}>
                  <PanelContentRightWrapper
                    isTabListNearTop={
                      !isMobileSize &&
                      !isValuationWizardOpen &&
                      isTabListNearTop
                    }
                  >
                    <InfrastructurePropertyWrapper />
                  </PanelContentRightWrapper>
                </PanelContentWrapper>
              </TabPanel>
              <TabPanel>
                <PanelContentWrapper isTabListNearTop={false}>
                  <PanelContentRightWrapper isTabListNearTop={false}>
                    <SimilarProperties />
                  </PanelContentRightWrapper>
                </PanelContentWrapper>
              </TabPanel>
              <TabPanel>
                <SocialEconomicsPropertyWrapper />
              </TabPanel>
              <TabPanel>
                <PanelContentWrapper isTabListNearTop={!isMobileSize}>
                  {!isMobileSize && (
                    <LeftSideMapboxContainer isTabListNearTop>
                      <Mapbox isVisible isMapAtBottomProp />
                    </LeftSideMapboxContainer>
                  )}
                  <PanelContentRightWrapper
                    isReachability
                    isTabListNearTop={!isMobileSize && !isValuationWizardOpen}
                  >
                    <Reachability
                      isTabListNearTop
                      transportPOI={propertyPOI?.transport as Transport}
                      coordinates={propertyCoordinates as Coordinates}
                      countryCode={countryCodeBase || 'DE'}
                    />
                  </PanelContentRightWrapper>
                </PanelContentWrapper>
              </TabPanel>
              <TabPanel>
                <BuyerFinderPage />
              </TabPanel>
            </Tabs>
          </ContentWithPadding>
          <EditValuationModal />
          <ScrollDetector
            containerReference={tabListRef}
            propertyFlyingTabs={new Set([1, 2, 4])}
          />
        </>
      )}
    </>
  );
};

const PropertyPage = memo(PropertyPageBase);

export { PropertyPage };
