import {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { VirtuosoGrid, VirtuosoHandle } from 'react-virtuoso';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as GridView } from 'assets/streamline-light/interface-essential/layouts/layout-module-1.svg';
import { ReactComponent as ListView } from 'assets/streamline-light/interface-essential/menu/navigation-menu-1.svg';

import { LoaderContainer } from 'modules/search-profile/pages/search-profile-page/search-profile-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import {
  Direction,
  ExposeRequest,
  ExposeStatus,
  OrderSuggestedPropertiesBy as OrderByEnum,
  SuggestedPropertiesFilterInput,
  useExposeRequestsQuery,
  useSuggestedPropertiesQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { Headline2 } from '../../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { scrollToProperty } from '../../../property/redux/dynamicMapUtilsSlice';
import {
  addFavouriteProperties,
  clearFavouritePropertiesCache,
  mergeFilters,
  setInitialLoader,
  setTotalItems,
} from '../../redux/favouritePropertiesSlice';
import { OrderBy } from '../suggested-properties/order-by';
import Icon from '../../../common/components/ui/icon';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import {
  SuggestedPropertyCard,
  CardTypes,
} from '../suggested-properties/suggested-property-card';
import { OnlineExposesResponse } from '../online-exposes/container';
import EmptyStatePlaceholder from './empty-state-placeholder';
import { SuggestedPropertiesResponse } from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties';
import { useSearchProfileQuery } from '../../../../services/graphql/enhanced';
import { useGetCountry } from '../../../localization/get-country';

const ItemContainer = styled.div<{ isShrinked: boolean; isListView?: boolean }>`
  padding: 0;
  margin: ${(props) => (props.isListView ? '0 0 12px 0' : '0 6px 12px 6px')};
  width: ${({ isShrinked, isListView }) =>
    isShrinked || isListView ? '100%' : 'calc(50% - 12px)'};
  min-width: ${(props) => (props.isListView ? '100%' : 'unset')};

  @media ${device.tablet} {
    width: 100%;
    margin: 0 0 12px 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  //margin: 0 -6px -12px -6px;
  width: 100%;
  height: 100%;
`;

const LoadMoreButtonContainer = styled.div<{
  isListView?: boolean;
  isMobileSize?: boolean;
}>`
  margin: ${(props) =>
    props.isListView || props.isMobileSize ? '40px 0 0 0' : '40px 6px 0 6px'};
`;

const LoadMoreButton = styled(SecondaryButton)`
  justify-content: center;
`;

const Header = styled.div<{ isShrinked: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  ${({ isShrinked }) =>
    isShrinked &&
    `
    >div:last-child {
      margin-left: 0;
      flex: 1;
      justify-content: space-between;
    }
  `} @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled(Headline2)<{ isListView?: boolean }>`
  margin-left: ${(props) => (props.isListView ? 0 : '6px')};

  @media ${device.tablet} {
    margin-left: unset;
  }
`;

const Count = styled.div`
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.ctaBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #fff;
`;

enum Views {
  LIST = 'List',
  GRID = 'Grid',
}

const FavouritesContainerBase = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [currentView, setCurrentView] = useState<Views>(Views.GRID);
  const isMobileSize = useIsMobileSize();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const { language } = useGetCountry();

  const searchProfileLocation = useAppSelector((state) => state.map.markers[0]);

  const isShrinked = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const filters = useAppSelector(
    (state) => state.favouriteProperties.filters
  ) as SuggestedPropertiesFilterInput;
  const favouriteProperties = useAppSelector(
    (state) => state.favouriteProperties.favouriteProperties
  );
  const totalItems = useAppSelector(
    (state) => state.favouriteProperties.totalItems
  );
  const initialLoader = useAppSelector(
    (state) => state.favouriteProperties.initialLoader
  );
  const propertyIndexForScrolling = useAppSelector(
    (state) => state.dynamicMapUtils.propertyIndexForScrolling
  );

  const {
    dislikedData: disliked,
    likedData: liked,
    priceOffersData: priceOffers,
    isLoading: isFavouriteListLoading,
    searchProfileDetails: searchProfileData,
    countryCodeBase: countryCode,
  } = useSearchProfileQuery(
    {
      id: searchProfileId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        const dislikedData = data?.searchProfile?.disliked;
        const likedData = data?.searchProfile?.liked;
        const priceOffersData = data?.searchProfile?.priceOffers;
        const searchProfileDetails = data?.searchProfile?.searchProfileData;

        return {
          dislikedData,
          likedData,
          priceOffersData,
          error,
          isLoading,
          isSuccess,
          searchProfileDetails,
          countryCodeBase: data?.searchProfile?.countryCode,
        };
      },
      refetchOnMountOrArgChange: true,
    }
  );

  const favouritePropertiesIds = useMemo(() => {
    return liked?.map((item) => item.id) ?? [];
  }, [liked]);

  const { data, isLoading: isSuggestedPropertiesLoading } =
    useSuggestedPropertiesQuery<SuggestedPropertiesResponse>(
      {
        filter: {
          offset: filters.offset ?? 0,
          limit: filters.limit ?? 8,
          countryCode,
          orderBy: {
            field: filters.orderBy?.field,
            direction: filters.orderBy?.direction,
            proximityParams: {
              latitude: searchProfileLocation?.latitude ?? 0,
              longitude: searchProfileLocation?.longitude ?? 0,
            },
          },
          favouritePropertiesIds,
          unsuitablePropertiesIds: disliked ?? [],
          searchProfileData,
        },
      },
      { skip: !disliked && !liked }
    );

  const { data: onlineExposes, isLoading: isOnlineExposesLoading } =
    useExposeRequestsQuery<OnlineExposesResponse>({
      searchProfileId,
    });

  const requestedProperties = useMemo(
    () =>
      onlineExposes?.exposeRequests?.page?.map(
        (expose: ExposeRequest) => expose.propertyDetails?.externalId
      ),
    [onlineExposes]
  );

  const loadMore = useCallback(() => {
    if (totalItems > (filters?.offset ?? 0)) {
      dispatch(
        mergeFilters({
          limit: 8,
          offset: +(filters.offset ?? 0) + 8,
        })
      );
    }
  }, [dispatch, filters?.offset, totalItems]);

  const switchOrder = useCallback(
    ({ field, direction }: { field: OrderByEnum; direction: Direction }) => {
      const currentLoadedCount = favouriteProperties.length;
      batch(() => {
        dispatch(clearFavouritePropertiesCache());
        dispatch(
          mergeFilters({
            limit: currentLoadedCount,
            offset: 0,
            orderBy: {
              field,
              direction: direction.toUpperCase(),
              proximityParams: {
                latitude: searchProfileLocation.latitude,
                longitude: searchProfileLocation.longitude,
              },
            },
          })
        );
        dispatch(setInitialLoader(true));
      });
    },
    [
      dispatch,
      searchProfileLocation?.latitude,
      searchProfileLocation?.longitude,
      favouriteProperties.length,
    ]
  );

  useEffect(() => {
    if (data?.suggestedProperties?.page) {
      batch(() => {
        dispatch(addFavouriteProperties(data?.suggestedProperties?.page));
        dispatch(setTotalItems(data?.suggestedProperties?.pageData.count));
        dispatch(setInitialLoader(false));
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (
      propertyIndexForScrolling !== null &&
      propertyIndexForScrolling !== undefined &&
      virtuosoRef?.current
    ) {
      dispatch(scrollToProperty(null));
      try {
        setTimeout(() => {
          virtuosoRef?.current?.scrollToIndex({
            index: propertyIndexForScrolling,
            align: 'center',
            behavior: 'smooth',
          });
        }, 0);
      } catch (error) {
        console.error('Error ->', error);
      }
    }
  }, [dispatch, propertyIndexForScrolling]);

  useEffect(() => {
    dispatch(clearFavouritePropertiesCache());
  }, [searchProfileData]);

  const unlockedExposes = useMemo(
    () =>
      onlineExposes?.exposeRequests?.page
        ?.filter(
          (expose: ExposeRequest) => expose.status === ExposeStatus.Unlocked
        )
        .map((expose: ExposeRequest) => ({
          exposeId: expose._id,
          propertyId: expose.propertyDetails?.externalId,
        })),
    [onlineExposes]
  );

  const ItemWithShrinkProps = useMemo(() => {
    return ({ children, ...props }: { children?: ReactNode }) => {
      return (
        <ItemContainer
          isShrinked={isShrinked}
          isListView={currentView === Views.LIST}
          {...props}
        >
          {children}
        </ItemContainer>
      );
    };
  }, [isShrinked, currentView]);

  const itemContent = useCallback(
    (index: number) => (
      <SuggestedPropertyCard
        propertyId={favouriteProperties?.[index]?.propertyId ?? 0}
        id={favouriteProperties?.[index]?.externalId ?? ''}
        internalId={favouriteProperties?.[index]?.internalId ?? ''}
        title={favouriteProperties?.[index]?.title ?? ''}
        price={favouriteProperties?.[index]?.price ?? 0}
        currency={favouriteProperties?.[index]?.currency ?? ''}
        numberOfRooms={favouriteProperties?.[index]?.numberOfRooms ?? 0}
        livingArea={favouriteProperties?.[index]?.livingArea ?? 0}
        landArea={favouriteProperties?.[index]?.landArea ?? 0}
        imageSrc={
          `https://erp.von-poll.com/import/images/${favouriteProperties?.[index]?.image[0].name}` ??
          ''
        }
        regionalAddition={favouriteProperties?.[index]?.regionalAddition || ''}
        location={favouriteProperties?.[index]?.city || ''}
        postCode={favouriteProperties?.[index]?.postalCode || ''}
        isListView={currentView === Views.LIST}
        url={`https://www.von-poll.com/${language}/expose/immobilie/${favouriteProperties?.[index]?.propertyId}`}
        hasRequestExpose
        isRequestedForExpose={requestedProperties?.includes(
          favouriteProperties?.[index]?.externalId
        )}
        isUnlocked={
          !!unlockedExposes?.find(
            (item) =>
              item.propertyId === favouriteProperties?.[index]?.externalId
          )
        }
        exposeRequestId={
          unlockedExposes?.find(
            (item) =>
              item.propertyId === favouriteProperties?.[index]?.externalId
          )?.exposeId ?? undefined
        }
        type={CardTypes.FAVOURITE}
        isDisabled={disliked?.includes(
          favouriteProperties?.[index]?.externalId
        )}
        offeredPrice={
          priceOffers?.find(
            (item) =>
              item.propertyInternalId ===
              favouriteProperties?.[index]?.internalId
          )?.offeredPrice
        }
        propertyType={favouriteProperties?.[index]?.type}
        countryCode={favouriteProperties?.[index]?.country}
      />
    ),
    [
      favouriteProperties,
      currentView,
      language,
      requestedProperties,
      disliked,
      countryCode,
    ]
  );

  if (
    initialLoader ||
    isFavouriteListLoading ||
    isOnlineExposesLoading ||
    isSuggestedPropertiesLoading
  ) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <>
      {liked?.length === 0 || favouriteProperties.length === 0 ? (
        <>
          <Header isShrinked={isShrinked}>
            <FlexContainer>
              <Title
                content={t('search-profile.favourites.title')}
                isListView={currentView === Views.LIST}
              />
              <Count>0</Count>
            </FlexContainer>
          </Header>
          <EmptyStatePlaceholder />
        </>
      ) : (
        <>
          <Header isShrinked={isShrinked}>
            <FlexContainer>
              <Title
                content={t('search-profile.favourites.title')}
                isListView={currentView === Views.LIST}
              />
              <Count>{favouriteProperties?.length}</Count>
            </FlexContainer>
            <OrderBy
              selectedOrderBy={filters.orderBy ?? {}}
              switchOrder={switchOrder}
            />
            {!isMobileSize && (
              <FlexContainer>
                <IconContainer onClick={() => setCurrentView(Views.GRID)}>
                  <Icon
                    icon={GridView}
                    width={16}
                    height={16}
                    color={
                      currentView === Views.GRID
                        ? themeContext.blue
                        : hexToRGB(themeContext.blue, 0.5)
                    }
                  />
                </IconContainer>
                <IconContainer onClick={() => setCurrentView(Views.LIST)}>
                  <Icon
                    icon={ListView}
                    color={
                      currentView === Views.LIST
                        ? themeContext.blue
                        : hexToRGB(themeContext.blue, 0.5)
                    }
                    style={{ marginLeft: '8px' }}
                  />
                </IconContainer>
              </FlexContainer>
            )}
          </Header>
          <VirtuosoGrid
            useWindowScroll
            totalCount={favouriteProperties?.length}
            overscan={200}
            ref={virtuosoRef}
            components={{
              Item: ItemWithShrinkProps,
              List: ListContainer,
            }}
            itemContent={itemContent}
          />
          {totalItems > favouriteProperties?.length && (
            <LoadMoreButtonContainer
              isListView={currentView === Views.LIST}
              isMobileSize={isMobileSize}
            >
              <LoadMoreButton
                fluid
                onClick={loadMore}
                label={t('button.load-more')}
                color={themeContext.blue}
                borderColor={themeContext.blue}
              />
            </LoadMoreButtonContainer>
          )}
        </>
      )}
    </>
  );
};

const FavouritesContainer = memo(FavouritesContainerBase);

export { FavouritesContainer };
