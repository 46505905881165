import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  height: 80px;
  padding: 12px 12px 0 12px;
  display: flex;
  gap: 12px;
  background-color: #e5e9ec;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
  }

  @media ${device.tablet} {
    height: 72px;
    gap: 16px;
    padding: 8px 16px 0 16px;
  }
`;

export const ItemBox = styled.div<{ active?: boolean }>`
  font-family: Roboto;
  min-width: 320px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  font-stretch: normal;
  font-style: normal;
  cursor: pointer;
  flex: 1 0 auto;
  height: 100%;
  ${(props) => props.active && 'border-bottom: 4px solid #002849;'}
`;

export const ItemTitle = styled.p`
  margin-bottom: 4px;
  text-transform: uppercase;
  opacity: 0.5;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
`;

export const ItemAddress = styled.p`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.23;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 1.33;
  }
`;

export const OuterContainer = styled.div`
  overflow: hidden;
  position: sticky;
  top: 80px;
  z-index: 2000;
  height: 80px;

  @media ${device.tablet} {
    position: relative;
    top: 0;
  }
`;

const ButtonContainer = styled.div`
  height: 80px;
  width: 45px;
  position: absolute;
  z-index: 1;

  @media ${device.tablet} {
    height: 72px;
  }
`;

export const LeftButtonContainer = styled(ButtonContainer)`
  top: 0;
  left: 0;

  @media ${device.tablet} {
    background-image: linear-gradient(
      to left,
      rgba(231, 235, 237, 0) 9%,
      #e7ebed 62%
    );
  }
`;

export const RightButtonContainer = styled(ButtonContainer)`
  top: 0;
  right: 0;

  @media ${device.tablet} {
    background-image: linear-gradient(
      to right,
      rgba(231, 235, 237, 0) 9%,
      #e7ebed 62%
    );
  }
`;

const ArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border: solid 2px #fff;
  background-color: ${(props) => props.theme.logoBlue};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  i {
    path {
      stroke-width: 2px;
    }
  }
`;

export const LeftButton = styled(ArrowButton)`
  left: -20px;
  i {
    margin-left: 14px;
  }
`;

export const RightButton = styled(ArrowButton)`
  right: -20px;
  z-index: 2;
  i {
    margin-right: 14px;
  }
`;
