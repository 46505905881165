import { useCallback } from 'react';
import { Currency, useGetChfRateQuery } from '../../../generated';
import useGetLocalization from '../../localization/get-localization';
import { formatNumber } from '../../property/utils/format-number';

export const useGetCHFRate = (): number => {
  const { data } = useGetChfRateQuery();

  return data?.rate || 1;
};

export const useConvertEURtoCHF = () => {
  const rate = useGetCHFRate();

  return useCallback(
    (amount: number) => {
      return amount * rate;
    },
    [rate]
  );
};

export const useConvertCHFtoEUR = () => {
  const rate = useGetCHFRate();

  return useCallback(
    (amount: number) => {
      return amount / rate;
    },
    [rate]
  );
};

export const useToLocalWithPropertyCurrency = () => {
  const lang = useGetLocalization();
  const convertEURtoCHF = useConvertEURtoCHF();
  const convertCHFtoEUR = useConvertCHFtoEUR();

  return (
    price: number,
    propertyCurrency: Currency = Currency.Eur,
    priceCurrencySeparate = false
  ): string | [string, string] => {
    if (propertyCurrency === Currency.Eur && lang !== 'de-ch') {
      if (priceCurrencySeparate) return [formatNumber(price), '€'];
      return `${formatNumber(price)} €`;
    }
    if (propertyCurrency === Currency.Eur && lang === 'de-ch') {
      if (priceCurrencySeparate)
        return [formatNumber(convertEURtoCHF(price)), 'CHF'];
      return `CHF ${formatNumber(convertEURtoCHF(price))}`;
    }

    if (propertyCurrency === Currency.Chf && lang === 'de-ch') {
      if (priceCurrencySeparate) return [formatNumber(price), 'CHF'];
      return `CHF ${formatNumber(price)}`;
    }
    if (propertyCurrency === Currency.Chf && lang !== 'de-ch') {
      if (priceCurrencySeparate)
        return [formatNumber(convertCHFtoEUR(price)), '€'];
      return `${formatNumber(convertCHFtoEUR(price))} €`;
    }

    return 'n/a';
  };
};
